import { onceConsented } from "@telia/b2x-cookie-consent";

// Note that the old URL used was https://cdn.blueconic.net/telia-se.js
export const bcSandboxHost = "https://telia-se.sb.blueconic.net/script.js";

function loadBlueconic(): void {
  const tag = document.createElement("script");
  tag.id = "single-spa-application:@telia/b2x-blueconic";
  tag.src = bcSandboxHost;
  tag.async = true;
  document.body.appendChild(tag);
}

export function bootstrap(): Promise<void> {
  if (window.location.hostname.endsWith("telia.se")) {
    onceConsented("C0002", "blueconic").then(loadBlueconic);
  }
  return Promise.resolve();
}

export function mount(): Promise<void> {
  return Promise.resolve();
}

export function unmount(): Promise<void> {
  return Promise.resolve();
}
